/* eslint-disable no-unused-expressions */
import Store from 'store'
import isEmptyObject from 'utils/is-empty-object'

export async function fetchDataToStartApp (to, from, next) {
  try {
    const awaitedPromises = []

    const waitForStories = isEmptyObject(Store.getters['stories/stories'])
    const storiesPromise = Store.dispatch('stories/loadAllStories')
    if (waitForStories) {
      awaitedPromises.push(storiesPromise)
    }

    const waitForUniverses = isEmptyObject(Store.getters['universes/universes'])
    const universesPromise = Store.dispatch('universes/loadAllUniverses')
    if (waitForUniverses) {
      awaitedPromises.push(universesPromise)
    }

    const waitForCredits = isEmptyObject(Store.getters['credits/credits'])
    const creditsPromise = Store.dispatch('credits/loadCredits')
    if (waitForCredits) {
      awaitedPromises.push(creditsPromise)
    }

    await Promise.all(awaitedPromises)
    next()
  }
  catch (error) {
    next(error)
  }
}
