import axios from 'axiosInstance'
import Vue from 'vue'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  all: {}
}

// getters
const getters = {
  books: (state) => {
    return state.all
  },
}

// actions
const actions = {
  loadBooks ({ commit }) {
    return axios.get(Routes.api_epopia_books_url())
      .then(response => {
        const books = response.data
        books.forEach((book) => commit('setBook', book))
        return books
      })
  },

  loadBook ({ commit }, bookId) {
    return axios.get(Routes.api_epopia_book_url(bookId))
      .then((response) => {
        commit('setBook', response.data)
        return response.data
      })
  },

  assignStoryToBook ({ commit }, payload) {
    return axios.patch(Routes.assign_story_api_epopia_book_url(payload.bookId, { story_id: payload.storyId }))
      .then(response => {
        commit('setBook', response.data)
        return response.data
      })
  }
}

// mutations
const mutations = {
  setBook (state, book) {
    Vue.set(state.all, book.id, book)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
