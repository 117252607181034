export const storyStatusColors = {
  new: '#F3C144',
  waiting_to_start: '#F3C144',
  active: '#38B6AB',
  paused: '#D52F16',
  waiting_for_credit: '#D52F16',
  over: '#00124E'
}

const SNACKBAR_TYPES = ['error', 'info', 'success', 'warning']

export const snackbarTypeIsValid = (type) => SNACKBAR_TYPES.includes(type)
