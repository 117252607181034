import prestashop from 'prestashop'

const baseUrl = prestashop.urls.base_url
const baseUri = baseUrl.replace(prestashop.urls.shop_domain_url, '')

if (process.env.NODE_ENV === 'development') {
  __webpack_public_path__ = 'http://localhost:8080/'
}
else {
  __webpack_public_path__ = `${baseUri}modules/epopiamyaccount/views/dist/`
}
