import Vue from 'vue'
import Vuex from 'vuex'
import stories from './modules/stories'
import universes from './modules/universes'
import tasks from './modules/tasks'
import books from './modules/books'
import credits from './modules/credits'
import user from './modules/user'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    universes,
    stories,
    tasks,
    books,
    credits,
    user,
    ui
  },
  strict: !globals.debugEnabled,
})
