import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from 'store'
import AccountAppRoutes from './routes/account-routes.js'
import i18n from 'i18n'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: AccountAppRoutes,
  mode: 'history',
  // @TODO depend on Customer current language
  base: '/',
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    }
    else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
  Store.commit('ui/setOverlay', true)
  next()
})

router.afterEach((to, from) => {
  Store.commit('ui/setCurrentTitle', i18n.tc(`pagesTitle.${to.name}`))
  setTimeout(() => {
    Store.commit('ui/setOverlay', false)
  }, 100)
})

router.onError((error) => {
  router.push({ name: 'error404', params: { error } })
})

export default router
