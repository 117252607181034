import Store from 'store'
import { needsAnswer, canBeAnswered, isPersonalizationTask } from 'utils/task-state-helpers'
import { storyCanBeAnswered, userCanEditChildInfos, userCanEditAddress, userCanSetStoryStartDate, userCanPauseStory, userCanDeleteStory, userCanActivateStory, userCanRequestClosure, userCanShareStory, userCanChangeNextStory } from 'utils/authorizations-helpers'

// route helpers
const routeFactory = (name) => (params) => ({ name, params })

const defaultRoute = routeFactory('storyOverview')
const errorRoute = routeFactory('error')
const taskDetailRoute = routeFactory('taskDetail')
const taskAnswerRoute = routeFactory('taskAnswer')
const taskOverviewRoute = routeFactory('taskOverview')

// route guards
export function checkIfStoryExists (to, from, next) {
  const storyId = to.params.storyId
  const story = Store.getters['stories/storyById'](storyId)
  if (!story) {
    next(errorRoute({ to }))
  }
  else {
    Store.dispatch('stories/setCurrentStory', story)
    Store.commit('universes/setCurrentUniverseId', story.universe_id)
    next()
  }
}

export async function checkIfTaskExists (to, from, next) {
  try {
    const story = Store.getters['stories/currentStory']
    const task = await Store.dispatch('tasks/loadTask', { storyId: story.id, taskId: to.params.taskId })
    Store.dispatch('tasks/setCurrentTask', task)
    next()
  }
  catch (error) {
    next(error)
  }
}

export async function getDefaultStoryRoute (to, from, next) {
  try {
    const story = Store.getters['stories/currentStory']
    const task = story.current_task
    if (needsAnswer(story.state, task.state)) {
      const fullTask = await Store.dispatch('tasks/loadTask', { storyId: story.id, taskId: task.id }) // TODO: peut être changer l'api pour ne pas devoir fetch la task et l'avoir directement sur la story
      Store.dispatch('tasks/setCurrentTask', fullTask)
      next(taskDetailRoute({ storyId: story.id, taskId: task.id }))
    }
    else {
      next(defaultRoute({ storyId: story.id }))
    }
  }
  catch (error) {
    next(error)
  }
}

export function getDefaultTaskRoute (to, from, next) {
  const story = Store.getters['stories/currentStory']
  const task = Store.getters['tasks/currentTask']
  if (needsAnswer(story.state, task.state) && storyCanBeAnswered(story) && isPersonalizationTask(story.current_task.pos)) {
    next(taskAnswerRoute({ storyId: story.id, taskId: task.id, recipientInfos: to.params.recipientInfos }))
  }
  else {
    next(taskOverviewRoute({ storyId: story.id, taskId: task.id }))
  }
}

export function checkIfTaskCanBeAnswered (to, from, next) {
  const story = Store.getters['stories/currentStory']
  const task = Store.getters['tasks/currentTask']
  if ((canBeAnswered(story.state, task.state) && storyCanBeAnswered(story))) {
    if (isPersonalizationTask(task.pos)) {
      if (userCanEditChildInfos(story)) {
        next()
      }
      else {
        next(errorRoute({ to }))
      }
    }
    else {
      next()
    }
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfAllowClosure (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (story.allow_closure) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfCanSetStartDate (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (story.can_set_start_date && userCanSetStoryStartDate(story)) {
    return next()
  }
  else {
    next(defaultRoute({ storyId: story.id }))
  }
}

export function checkIfUserCanEditChildInfos (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanEditChildInfos(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanEditAddress (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanEditAddress(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanPauseStory (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanPauseStory(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanDeleteStory (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanDeleteStory(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanActivateStory (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanActivateStory(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanRequestClosure (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanRequestClosure(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanShareStory (to, from, next) {
  const story = Store.getters['stories/currentStory']
  if (userCanShareStory(story)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserIsSchool (to, from, next) {
  const userIsSchool = Store.getters['ui/userIsSchool']
  if (userIsSchool) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}

export function checkIfUserCanChangeNextStory (to, from, next) {
  const story = Store.getters['stories/currentStory']
  const userIsSchool = Store.getters['ui/userIsSchool']
  if (!userIsSchool && userCanChangeNextStory(story.current_task.episode)) {
    next()
  }
  else {
    next(errorRoute({ to }))
  }
}
