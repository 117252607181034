import Store from 'store'

export async function fetchCredits (to, from, next) {
  try {
    await Store.dispatch('credits/loadCredits')
    next()
  }
  catch (error) {
    next(error)
  }
}
