
export const allowTaskUpdate = (story) => story.current_user_rights.allow_task_update === true

export const allowStoryUpdate = (story) => story.current_user_rights.allow_story_update === true

export const allowSelectionForStorybook = (story) => story.current_user_rights.allow_selection_for_storybook === true

export const isMainUser = (story) => story.current_user_rights.main === true

export const storyCanBeAnswered = (story) => allowTaskUpdate(story)

export const userCanEditChildInfos = (story) => allowStoryUpdate(story)

export const userCanEditAddress = (story) => allowStoryUpdate(story)

export const userCanSetStoryStartDate = (story) => allowStoryUpdate(story)

export const userCanPauseStory = (story) => allowStoryUpdate(story)

export const userCanDeleteStory = (story) => isMainUser(story)

export const userCanActivateStory = (story) => allowStoryUpdate(story)

export const userCanRequestClosure = (story) => allowStoryUpdate(story)

export const userCanShareStory = (story) => isMainUser(story)

export const userCanBuyStoryBook = (story) => allowSelectionForStorybook(story)

export const userCanChangeNextStory = (currentEpisode) => currentEpisode && currentEpisode.penultimate
