/* Possible states are:
  'new'
  'waiting_to_start'
  'starting'
  'active'
  'paused'
  'waiting_for_credit'
  'over'
*/

export const isNew = (state) => state === 'new'

export const isActive = (state) => state === 'active' || state === 'new' || state === 'waiting_to_start'

export const isWaitingToStart = (state) => state === 'waiting_to_start'

export const isWaitingForCredit = (state) => state === 'waiting_for_credit'

export const isOver = (state) => state === 'over'

export const isPaused = (state) => state === 'paused' || state === 'inactive' || state === 'canceled' // TODO Change, inactive and pause are used only for development purposes

export const canBePaused = (state) => isActive(state)

export const canBeFinished = (state) => !isOver(state)

export const canBeActivated = (state) => isPaused(state)
