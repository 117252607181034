import axios from 'axiosInstance'
import Vue from 'vue'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  all: {},
  currentTaskId: null
}

// getters
const getters = {
  tasksByStoryId: (state) => (storyId) => {
    return Object.values(state.all).filter((task) => task.story_id === storyId)
  },
  currentTask: (state) => state.all[state.currentTaskId] || null
}

// actions
const actions = {
  loadStoryTasks ({ commit }, storyId) {
    return axios.get(Routes.api_epopia_story_tasks_url(storyId))
      .then(response => response.data)
      .then((tasks) => {
        tasks.forEach((task) => commit('setTask', task))
        return tasks
      })
  },

  setCurrentTask ({ commit }, task) {
    commit('setCurrentTaskId', task.id)
    commit('setTask', task)
  },

  loadTask ({ commit }, payload) {
    return axios.get(Routes.api_epopia_story_task_url(payload.storyId, payload.taskId, { include_content: true }))
      .then(response => response.data)
      .then((task) => {
        commit('setTask', task)
        return task
      }
      )
  },

  answerTask ({ commit }, payload) {
    return axios.put(Routes.api_epopia_story_task_url(payload.storyId, payload.taskId), { parameters: payload.answers })
      .then(response => response.data)
      .then((task) => {
        commit('setTask', task)
        commit('stories/setStoryCurrentTask', task, { root: true })
        return task
      })
  },

  addComment ({ commit }, payload) {
    return axios.post(Routes.api_epopia_story_task_response_comments_url(payload.storyId, payload.taskId), { task_response_comment: { value: payload.comment } })
      .then((response) => {
        commit('setComment', { taskId: payload.taskId, comment: response.data })
      })
      .catch(console.log)
  },
  removeTaskUpload ({ commit }, payload) {
    return axios.delete(Routes.api_epopia_story_task_response_upload_url(payload.storyId, payload.taskId, payload.uploadId))
      .then((response) => {
        commit('removeUploadFromTask', { uploadId: payload.uploadId, taskId: payload.taskId })
        return true
      })
  }
}

// mutations
const mutations = {
  setTask (state, task) {
    Vue.set(state.all, task.id, task)
  },
  setCurrentTaskId (state, taskId) {
    state.currentTaskId = taskId
  },
  setTaskUpload (state, payload) {
    state.all[payload.taskId].response.uploads.push(payload.upload)
  },
  removeUploadFromTask (state, { taskId, uploadId }) {
    const task = state.all[taskId]
    if (task) {
      const uploadIndex = task.response.uploads.findIndex(upload => upload.id === uploadId)
      state.all[taskId].response.uploads.splice(uploadIndex, 1)
    }
  },
  setComment (state, payload) {
    state.all[payload.taskId].response.comments.push(payload.comment)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
