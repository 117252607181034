import * as AppGuard from 'routes/app-route-guards'
import * as StoryGuard from 'routes/story-route-guards'
import * as UniverseGuard from 'routes/universe-guards'
import * as StoryBookGuard from 'routes/story-book-route-guards'
import * as CreditGuard from 'routes/credit-route-guards'

import prestashop from 'prestashop'

const AppRouter = () => import('components/AppRouter')
const HomePage = () => import('components/home/HomePage')
const ErrorPage = () => import('components/ErrorPage')

const StoriesRouter = () => import('components/stories/StoriesRouter')
const StoriesPage = () => import('components/stories/StoriesPage')
const StoryRouter = () => import('components/stories/StoryRouter')
const StoryOverview = () => import('components/stories/story-detail/overview/StoryOverview')
const TaskRouter = () => import('components/stories/story-detail/task/TaskRouter')
const TaskOverview = () => import('components/stories/story-detail/task/overview/TaskOverview')
const TaskAnswer = () => import('components/stories/story-detail/task/TaskAnswer')
const RecipientInfos = () => import('components/stories/story-detail/recipient/RecipientInfos')
const RecipientAddress = () => import('components/stories/story-detail/recipient/RecipientAddress')
const RequestClosure = () => import('components/stories/story-detail/actions/request-closure/RequestClosure')
const PauseStory = () => import('components/stories/story-detail/actions/pause/PauseStory')
const ShareStory = () => import('components/stories/story-detail/actions/share/ShareStory')
const ShareStorySchool = () => import('components/stories/ShareStorySchool')
const ActivateStory = () => import('components/stories/story-detail/actions/activate/ActivateStory')
const DeleteStory = () => import('components/stories/story-detail/actions/delete/DeleteStory')
const StoryStartDate = () => import('components/stories/story-detail/actions/start-date/StoryStartDate')
const NextStory = () => import('components/stories/story-detail/actions/next-story-creation/NextStory')
const UniversesRouter = () => import('components/universes/UniversesRouter')
const UniversesPage = () => import('components/universes/UniversesPage')
const UniverseDetail = () => import('components/universes/UniverseDetail')
const StoryBooksRouter = () => import('components/story-books/StoryBooksRouter')
const StoryBooks = () => import('components/story-books/StoryBooks')
const StoryBookDetail = () => import('components/story-books/StoryBookDetail')
const CreditsOverview = () => import('components/credits/CreditsOverview')
const ReferralsPage = () => import('components/referrals/ReferralsPage')
const CreateStory = () => import('components/CreateStory')

const baseUrl = prestashop.urls.shop_domain_url
const myAccountUrl = prestashop.urls.pages.my_account
const myAccountPath = myAccountUrl.replace(baseUrl, '/')

const routes = [
  {
    path: myAccountPath,
    component: AppRouter,
    beforeEnter: AppGuard.fetchDataToStartApp,
    children: [
      { path: '', redirect: { name: 'home' } },
      { path: 'home', name: 'home', component: HomePage },
      {
        path: 'my-stories',
        name: 'stories',
        component: StoriesRouter,
        children: [
          { path: '', name: 'storiesList', component: StoriesPage },
          { path: 'share-story-school', name: 'shareStorySchool', beforeEnter: StoryGuard.checkIfUserIsSchool, component: ShareStorySchool },
          {
            path: ':storyId',
            name: 'story',
            component: StoryRouter,
            beforeEnter: StoryGuard.checkIfStoryExists,
            children: [
              { path: '', name: 'storyDetail', beforeEnter: StoryGuard.getDefaultStoryRoute },
              { path: 'overview', name: 'storyOverview', component: StoryOverview },
              {
                path: 'task/:taskId',
                name: 'task',
                component: TaskRouter,
                beforeEnter: StoryGuard.checkIfTaskExists,
                children: [
                  { path: '', name: 'taskDetail', beforeEnter: StoryGuard.getDefaultTaskRoute },
                  { path: 'overview', name: 'taskOverview', component: TaskOverview },
                  { path: 'answer', name: 'taskAnswer', beforeEnter: StoryGuard.checkIfTaskCanBeAnswered, component: TaskAnswer },
                ]
              },
              { path: 'recipient-infos', name: 'recipientInfos', beforeEnter: StoryGuard.checkIfUserCanEditChildInfos, component: RecipientInfos },
              { path: 'recipient-address', name: 'recipientAddress', beforeEnter: StoryGuard.checkIfUserCanEditAddress, component: RecipientAddress },
              { path: 'request-closure', name: 'requestClosure', beforeEnter: StoryGuard.checkIfAllowClosure && StoryGuard.checkIfUserCanRequestClosure, component: RequestClosure },
              { path: 'pause-story', name: 'pauseStory', beforeEnter: StoryGuard.checkIfUserCanPauseStory, component: PauseStory },
              { path: 'activate-story', name: 'activateStory', beforeEnter: StoryGuard.checkIfUserCanActivateStory, component: ActivateStory },
              { path: 'delete-story', name: 'deleteStory', beforeEnter: StoryGuard.checkIfUserCanDeleteStory, component: DeleteStory },
              { path: 'start-date', name: 'storyStartDate', beforeEnter: StoryGuard.checkIfCanSetStartDate, component: StoryStartDate },
              { path: 'share-story', name: 'shareStory', beforeEnter: StoryGuard.checkIfUserCanShareStory, component: ShareStory },
              { path: 'next-story', name: 'nextStory', beforeEnter: StoryGuard.checkIfUserCanChangeNextStory, component: NextStory }
            ]
          },
        ]
      },
      {
        path: 'universes',
        component: UniversesRouter,
        name: 'universes',
        beforeEnter: UniverseGuard.fetchUniverses,
        children: [
          { path: '', name: 'universesList', component: UniversesPage },
          { path: ':universeId', name: 'universeDetail', component: UniverseDetail, beforeEnter: UniverseGuard.checkIfUniverseExists }
        ]
      },
      {
        path: 'books',
        component: StoryBooksRouter,
        name: 'storyBooks',
        beforeEnter: StoryBookGuard.fetchDataForStoryBooks,
        children: [
          { path: '', name: 'storyBooksList', component: StoryBooks },
          { path: ':bookId', name: 'storyBookDetail', component: StoryBookDetail, beforeEnter: StoryBookGuard.checkIfStoryBookExists }]
      },
      { path: 'create-story', name: 'createStory', component: CreateStory },
      { path: 'credits', component: CreditsOverview, name: 'credits', beforeEnter: CreditGuard.fetchCredits },
      { path: 'referrals', component: ReferralsPage, name: 'referrals' },
      { path: 'error', name: 'error', component: ErrorPage, props: true }
    ]
  },
  { path: '*', redirect: { name: 'home' } }
]

export default routes
