<template>
  <div class="vuetify">
    <h1>{{ currentTitle }}</h1>
    <v-app>
      <Breadcrumbs />
      <router-view />
      <v-overlay :value="showOverlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <Snackbar />
    </v-app>
    <ExternalRouterLinks :external-router-links="externalRouterLinks" />
    <StateManager v-if="debugEnabled" />
  </div>
</template>

<script>
import Breadcrumbs from 'components/Breadcrumbs'
import Snackbar from 'components/Snackbar'
import ExternalRouterLinks from 'components/ExternalRouterLinks'
import prestashop from 'prestashop'
const StateManager = () => import('components/dev/StateManager')

export default {
  name: 'App',
  components: {
    Breadcrumbs,
    Snackbar,
    ExternalRouterLinks,
    StateManager
  },
  data () {
    return {
      externalRouterLinks: ['storiesList', 'home', 'storyBooksList'],
      debugEnabled: globals.debugEnabled
    }
  },
  computed: {
    showOverlay () {
      return this.$store.getters['ui/showOverlay']
    },
    currentTitle () {
      return this.$store.getters['ui/currentTitle']
    }
  },
  created () {
    const locale = prestashop?.language?.iso_code || 'en'
    this.$store.commit('ui/setLocale', locale)
    const countries = prestashop?.modules?.epopiacore?.ACTIVE_COUNTRIES
    if (!countries) {
      console.error('Countries are missing from epopia-core module')
    }
    else {
      this.$store.commit('ui/setCountries', countries)
    }
    const universesBusinessInfos = prestashop?.modules?.epopiacore?.EPOPIA_AVAILABLE_UNIVERSES
    this.$store.commit('universes/setUniversesBusinessInfos', universesBusinessInfos)
    const subscriptionsPath = prestashop?.modules?.epopiacore?.PM_SUBSCRIPTION_URL
    if (!subscriptionsPath) {
      console.error('PM subscription url is missing from epopia-core-module')
    }
    else {
      this.$store.commit('ui/setSubscriptionsPath', subscriptionsPath)
    }
    const storyBookIsAvailable = prestashop?.modules?.epopiacore?.STORYBOOK_AVAILABLE
    this.$store.commit('ui/setStoryBookAvailability', storyBookIsAvailable)
    const newSubscriptionPath = prestashop?.modules?.epopiacore?.LANDING_CHILD_OFFERS
    // TODO change according user type
    if (!newSubscriptionPath) {
      console.error('PM LANDING_CHILD_OFFERS is missing from epopia-core-module')
    }
    else {
      this.$store.commit('ui/setNewSubscriptionPath', newSubscriptionPath)
    }
    const userIsSchool = prestashop?.modules?.epopiacore?.IS_SCHOOL
    this.$store.commit('ui/setUserType', userIsSchool)

    const renewalDate = prestashop?.modules?.epopiacore?.EPOPIA_SUBSCRIPTION_NEXT_RENEW_DATE
    this.$store.commit('ui/setRenewalDate', renewalDate)

    const storyBookPath = prestashop?.modules?.epopiacore?.LANDING_STORYBOOK_OFFERS
    if (!storyBookPath || storyBookPath === '') {
      console.error('LANDING_STORYBOOK_OFFERS is missing from module epopia core')
    }
    else {
      this.$store.commit('ui/setStoryBookPath', storyBookPath)
    }
  }
}
</script>
<style lang="scss">
</style>
