import Store from 'store'
import isEmptyObject from 'utils/is-empty-object'

export async function fetchUniverses (to, from, next) {
  try {
    const awaitedPromises = []

    const waitForUniverses = isEmptyObject(Store.getters['universes/universes'])
    const universesPromise = Store.dispatch('universes/loadAllUniverses')
    if (waitForUniverses) {
      awaitedPromises.push(universesPromise)
    }

    const waitForCredits = isEmptyObject(Store.getters['credits/credits'])
    const creditsPromise = Store.dispatch('credits/loadCredits')
    if (waitForCredits) {
      awaitedPromises.push(creditsPromise)
    }

    await Promise.all(awaitedPromises)
    next()
  }
  catch (error) {
    next(error)
  }
}

export function checkIfUniverseExists (to, from, next) {
  const universeId = to.params.universeId
  const universe = Store.getters['universes/universeById'](universeId)
  if (universe) {
    Store.dispatch('universes/setCurrentUniverse', universe)
    next()
  }
  else {
    throw new Error('Universe doesn\'t exist')
  }
}
