import axios from 'axiosInstance'
import Vue from 'vue'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  all: {},
  currentUniverseId: null,
  universesBusinessInfos: {}
}

// getters
const getters = {
  universes: state => state.all,
  universeById: state => id => state.all[id],
  currentUniverse: (state) => state.all[state.currentUniverseId] || null,
  universesBusinessInfos: state => state.universesBusinessInfos,
  visibleUniverses: (state, getters) => Object.values(getters.universes).filter((universe) => getters.universesBusinessInfos[universe.name].visible),
  universesAvailableForChild: (state, getters) => getters.visibleUniverses.filter((universe) => universe.available_for_child),
  universesAvailableForFrenchSchool: (state, getters) => getters.visibleUniverses.filter((universe) => universe.available_for_french_school)
}

// actions
const actions = {
  loadAllUniverses ({ commit }) {
    return axios.get(Routes.api_epopia_universes_url(), { params: { all: true } })
      .then((response) => {
        const universes = response.data
        universes.forEach(universe => commit('setUniverse', universe))
      })
  },
  setCurrentUniverse ({ commit }, universe) {
    commit('setCurrentUniverseId', universe.id)
  },
}

// mutations
const mutations = {
  setUniverse (state, universe) {
    Vue.set(state.all, universe.id, universe)
  },
  setCurrentUniverseId (state, universeId) {
    state.currentUniverseId = universeId
  },
  setUniversesBusinessInfos (state, universesBusinessInfos) {
    state.universesBusinessInfos = universesBusinessInfos
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
