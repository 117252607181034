<template>
  <v-breadcrumbs :items="breadcrumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="{ name: (item.linksTo || item.routeName), params: item.routeParams }"
        :exact="true"
      >
        {{ (item.text ||
          $t(`breadcrumbs.${item.i18nKey || item.routeName}`, item.i18nParams)).toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
import { isPersonalizationTask } from 'utils/task-state-helpers'

export default {
  data () {
    return {
    }
  },
  computed: {
    // routeName is the route that must be matched so the breadcrumb is displayed
    // linksTo is the route the breadcrumbs links to in case it's it's a different route
    routes () {
      return [
        {
          routeName: 'stories',
          linksTo: 'storiesList'
        },
        {
          routeName: 'story',
          linksTo: 'storyOverview',
          routeParams: this.storyRouteParams,
          i18nParams: this.storyI18nParams
        },
        {
          routeName: 'task',
          linksTo: 'taskOverview',
          routeParams: this.taskRouteParams,
          i18nKey: this.isPersonalizationTask ? 'personalizationTask' : 'task',
          i18nParams: this.taskI18nParams
        },
        {
          routeName: 'taskAnswer'
        },
        {
          routeName: 'recipientInfos'
        },
        {
          routeName: 'recipientAddress'
        },
        {
          routeName: 'storyPersonalization'
        },
        {
          routeName: 'storyPersonalizationStepper'
        },
        {
          routeName: 'pauseStory'
        },
        {
          routeName: 'requestClosure'
        },
        {
          routeName: 'activateStory'
        },
        {
          routeName: 'deleteStory'
        },
        {
          routeName: 'storyStartDate'
        },
        {
          routeName: 'nextStory'
        },
        {
          routeName: 'universes',
          linksTo: 'universesList'
        },
        {
          routeName: 'universeDetail',
          text: this.currentUniverse?.story_name
        },
        {
          routeName: 'storyBooks',
          linksTo: 'storyBooksList'
        },
        {
          routeName: 'storyBookDetail'
        },
        {
          routeName: 'credits'
        },
        {
          routeName: 'referrals'
        },
      ]
    },
    breadcrumbs () {
      return [
        { routeName: 'home' }, // Always displayed
        ...this.routes.filter((route) => this.routeIsMatched(route.routeName))
      ]
    },

    // Story
    story () {
      return this.$store.getters['stories/currentStory']
    },
    universeName () {
      return this.story
        ? this.$store.getters['universes/universeById'](this.story.universe_id).story_name
        : null
    },
    recipientName () {
      return this.story?.recipient?.fullname ? this.story.recipient.fullname : this.$t(`recipientInfos.blankFullname.${this.story?.recipient?.type || "default"}`)
    },
    storyI18nParams () {
      if (this.universeName && this.recipientName) {
        return {
          universe: this.universeName,
          name: this.recipientName
        }
      }
      else {
        return {}
      }
    },
    storyRouteParams () {
      if (this.story) {
        return { story: this.story.id }
      }
      else {
        return {}
      }
    },

    // Task
    task () {
      return this.$store.getters['tasks/currentTask']
    },
    isPersonalizationTask () {
      return this.task && isPersonalizationTask(this.task.pos)
    },
    taskI18nParams () {
      if (this.task) {
        return {
          pos: this.task.pos
        }
      }
      else {
        return {}
      }
    },
    taskRouteParams () {
      if (this.story && this.task) {
        return { story: this.story.id, task: this.task.pos }
      }
      else {
        return {}
      }
    },

    // Universe
    currentUniverse () {
      return this.$store.getters['universes/currentUniverse']
    }
  },
  methods: {
    routeIsMatched (routeName) {
      return this.$route.matched.some(({ name }) => name === routeName)
    }
  }
}
</script>

<style>

</style>
