import './public_path'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
Vue.config.devtools = globals.debugEnabled
//Vue.config.devtools = true

import router from './router'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'
import prestashop from 'prestashop'
import store from './store'
import vuetify from './vuetify'
import App from './components/App.vue'
import i18n from './i18n'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import PortalVue from 'portal-vue'
//Sentry
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://bd45c76249f44cdbbd522fe5be8bcf0a@sentry.epopia.com/8",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });
}


const locale = prestashop?.language?.iso_code || 'en'

dayjs.extend(localizedFormat)
dayjs.locale(locale)

Vue.filter('formatDate', function (value) {
  if (!value || !dayjs(value).isValid()) return ''
  return dayjs(value).format('LL')
})

Vue.mixin({
  computed: {
    userIsSchool: () => store.getters['ui/userIsSchool']
  }
})

Vue.use(PortalVue)

Routes.configure({
  default_url_options: {
    host: prestashop.modules.epopiacore.EPOPIA_API_HOST,
    port: prestashop.modules.epopiacore.EPOPIA_API_PORT,
    protocol: prestashop.modules.epopiacore.EPOPIA_API_PROTOCOL
  }
})

const appHtmlElement = document.getElementsByTagName('app')[0]

if (appHtmlElement) {
  new Vue({ // eslint-disable-line no-new
    el: appHtmlElement,
    propsData: {
      customer: {
        type: String,
        default: null
      },
      locale: {
        type: String,
        default: null
      }
    },
    i18n,
    router,
    store,
    vuetify,
    render (h) {
      return h(App, {
        props: {
        }
      })
    }
  })
}
