<template>
  <div>
    <MountingPortal
      v-for="routeName in externalRouterLinks"
      :key="`${routeName}_mobile`"
      :mount-to="`.epopiamyaccount_${routeName}_mobile`"
    >
      <router-link
        :to="{ name: routeName }"
        @click.native="closeMobileMenu()"
      >
        {{ $t(`navigation.${routeName}`) }}
      </router-link>
    </MountingPortal>
    <MountingPortal
      v-for="routeName in externalRouterLinks"
      :key="`${routeName}_sidebar`"
      :mount-to="`.epopiamyaccount_${routeName}_sidebar`"
    >
      <router-link :to="{ name: routeName }">
        {{ $t(`navigation.${routeName}`) }}
      </router-link>
    </MountingPortal>
  </div>
</template>

<script>
export default {
  props: {
    externalRouterLinks: {
      type: Array,
      required: true
    }
  },
  methods: {
    // Copy of a toggle function in adipso.js line 86 in epopia theme
    closeMobileMenu () {
      const burgerMenu = $('.js-menu-burger')
      burgerMenu.toggleClass('is-active')

      if (burgerMenu.hasClass('is-active')) {
        $('.menu').css('display', 'flex').hide().fadeIn()
      }
      else {
        $('.menu').fadeOut()
      }

      $('body').toggleClass('noscroll')
    }
  }
}
</script>

<style>

</style>
