import { snackbarTypeIsValid } from 'utils/ui'

// initial state
const state = {
  showOverlay: false,
  showSnackbar: false,
  snackbarText: '',
  snackbarType: null,
  currentTitle: '',
  locale: 'en',
  countries: {},
  subscriptionsPath: null,
  newSubscriptionPath: null,
  storyBookIsAvailable: false,
  userIsSchool: false,
  renewalDate: null,
  storyBookPath: null
}

// getters
const getters = {
  showOverlay: (state) => state.showOverlay,
  showSnackbar: (state) => state.showSnackbar,
  snackbarText: (state) => state.snackbarText,
  snackbarType: (state) => state.snackbarType,
  currentTitle: (state) => state.currentTitle,
  locale: (state) => state.locale,
  countries: (state) => state.countries,
  subscriptionsPath: (state) => state.subscriptionsPath,
  newSubscriptionPath: (state) => state.newSubscriptionPath,
  storyBookIsAvailable: (state) => state.storyBookIsAvailable,
  userIsSchool: (state) => state.userIsSchool,
  renewalDate: (state) => state.renewalDate,
  storyBookPath: (state) => state.storyBookPath
}

// actions
const actions = {
}

// mutations
const mutations = {
  setOverlay (state, showOverlay) {
    state.showOverlay = showOverlay
  },
  showSnackbar (state, { text, type }) {
    state.snackbarText = text
    state.showSnackbar = true
    state.snackbarType = type && snackbarTypeIsValid(type) ? type : 'info'
  },
  hideSnackbar (state) {
    state.showSnackbar = false
  },
  setCurrentTitle (state, title) {
    state.currentTitle = title
  },
  setLocale (state, locale) {
    state.locale = locale
  },
  setCountries (state, countries) {
    state.countries = countries
  },
  setSubscriptionsPath (state, subscriptionsPath) {
    state.subscriptionsPath = subscriptionsPath
  },
  setNewSubscriptionPath (state, newSubscriptionPath) {
    state.newSubscriptionPath = newSubscriptionPath
  },
  setStoryBookAvailability (state, storyBookIsAvailable) {
    state.storyBookIsAvailable = storyBookIsAvailable
  },
  setUserType (state, userIsSchool) {
    state.userIsSchool = userIsSchool
  },
  setRenewalDate (state, renewalDate) {
    state.renewalDate = renewalDate
  },
  setStoryBookPath (state, storyBookPath) {
    state.storyBookPath = storyBookPath
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
