import axios from 'axios'
import * as rax from 'retry-axios'
import Store from 'store'
import i18n from 'i18n'
import prestashop from 'prestashop'

const token = prestashop.modules.epopiacore.EPOPIA_TOKEN

const snackbar = (text) => Store.commit('ui/showSnackbar', { text, type: 'warning' })
const instance = axios.create({
  headers: { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${token}` }
})

instance.defaults.raxConfig = {
  instance: instance,
  retryDelay: 1000,
  backoffType: 'linear',
  noResponseRetries: 9,
  onRetryAttempt: () => {
    snackbar(i18n.tc('common.retryAttemptMessage'))
  }
}
rax.attach(instance)

export default instance
