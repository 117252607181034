import axios from 'axiosInstance'
import Routes from '@lirec/routesjs-epopia/dist/index.min.js'

// initial state
const state = {
  user: {},
  cart: null,
  defaultStripePaymentMethod: null,
}

// getters
const getters = {
  user: state => state.user
}

// actions
const actions = {
  loadUser ({ commit, state }) {
    return axios.get(Routes.api_epopia_current_user_url())
      .then((response) => {
        commit('setUser', response.data)
        return state.user
      })
  }
}

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
