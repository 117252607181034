import { isNew, isActive } from 'utils/story-state-helpers'

const STATES = {
  CANCELLED: 5, // the task has been cancelled, e.g. after a refund, and should not start
  RESTARTED: 6, // transitory state a task takes if cancelled then a new order comes
  WAITING: 10, // the task has been created
  PREVIOUS_TASK_USER_ANSWERED: 15, // user answer received (or file uploaded) on previous task and should be checked
  NOT_STARTED: 20, // The task has been selected
  GENERATION_FAILED: 25, // Problem with task generation when required by bundle
  GENERATION_HELD: 28, // The generation has been manually held in this state to prevent sending
  GENERATED: 30, // Automatically generated
  CONTENT_VALIDATED: 35, // Manually generated
  PRODUCTION_CANCELLED: 36, // The production of the task have been cancelled.
  MARKED_FOR_RESEND: 37, // The task is marked for resend, which will make it possible then switch to TO_RESEND
  TO_RESEND: 38, // This should be sent again
  IN_PRODUCTION: 39, // Already in production
  SENT: 40, // Sent, can be answered
  SLEEPING: 42, // Sent but not answered after 45 days
  EXPIRED: 43, // After reaching expire_on date, we can begin next task
  INTEGRATION_REFUSED: 44,
  USER_ANSWERED: 45, // Answered by users
  USER_FILE_ANSWERED: 47, // The user has uploaded a file for the answer
  ANSWER_RECEIVED: 48, // The answer has been received but not yet processed
  ANSWERED: 50, // Answers validated
  AUTO_ANSWERED: 55, // Automatic answering
  FINISHED: 60, // Finished
  TERMINATED: 65, // No next task
  ULTIMATE_EPISODE_TERMINATED: 67, // No next task because the task's episode is ultimate (end of story)
  DONE: 70, // Already to the next task
}

export const taskIsAlreadyAnswered = (state) => state >= STATES.USER_ANSWERED

export const taskCanBeAnswered = (state) => (taskIsSent(state) && state < STATES.ANSWERED)

export const needsPersonalization = (storyState, taskState) => (isNew(storyState) && !taskIsAlreadyAnswered(taskState))

export const needsAnswer = (storyState, taskState) => (isActive(storyState) && taskIsSent(taskState) && !taskIsAlreadyAnswered(taskState))

export const canBeAnswered = (storyState, taskState) => (isActive(storyState) && taskCanBeAnswered(taskState))

export const isPersonalizationTask = (taskPos) => taskPos === 0

export const taskIsSent = (state) => state >= STATES.SENT

export const taskIsAnswerReceived = (state) => state === STATES.ANSWER_RECEIVED

export const taskIsUserAnswered = (state) => [STATES.USER_ANSWERED, STATES.USER_FILE_ANSWERED].includes(state)

export const taskIsGenerated = (state) => state === STATES.GENERATED

export const taskIsInProduction = (state) => state === STATES.IN_PRODUCTION

export const taskIsToResend = (state) => state === STATES.TO_RESEND
