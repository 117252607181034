/* eslint-disable no-unused-expressions */
import Store from 'store'
import isEmptyObject from 'utils/is-empty-object'

export async function fetchDataForStoryBooks (to, from, next) {
  try {
    const awaitedPromises = []

    const waitForStories = isEmptyObject(Store.getters['stories/stories'])
    const storiesPromise = Store.dispatch('stories/loadAllStories')
    if (waitForStories) {
      awaitedPromises.push(storiesPromise)
    }

    const waitForUniverses = isEmptyObject(Store.getters['universes/universes'])
    const universesPromise = Store.dispatch('universes/loadAllUniverses')
    if (waitForUniverses) {
      awaitedPromises.push(universesPromise)
    }

    const waitForBooks = isEmptyObject(Store.getters['books/books'])
    const booksPromise = Store.dispatch('books/loadBooks')
    if (waitForBooks) {
      awaitedPromises.push(booksPromise)
    }

    const waitForCredits = isEmptyObject(Store.getters['credits/credits'])
    const creditsPromise = Store.dispatch('credits/loadCredits')
    if (waitForCredits) {
      awaitedPromises.push(creditsPromise)
    }

    const waitForUser = isEmptyObject(Store.getters['user/user'])
    const userPromise = Store.dispatch('user/loadUser')
    if (waitForUser) {
      awaitedPromises.push(userPromise)
    }

    await Promise.all(awaitedPromises)
    next()
  }
  catch (error) {
    next(error)
  }
}

export function checkIfStoryBookExists (to, from, next) {
  try {
    const books = Store.getters['books/books']
    const book = books[to.params.bookId]
    if (book) {
      next()
    }
  }
  catch (error) {
    next(error)
  }
}
